import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  return (
    <form>
      <div className="d-flex">
        <label htmlFor="header-search">
          <span className="visually-hidden">Search site content</span>
        </label>
        <div class="input-group mb-3">
          <span class="input-group-text">
            <AiOutlineSearch />
          </span>
          <input
            value={searchQuery}
            onInput={(e) => setSearchQuery(e.target.value)}
            type="text"
            id="header-search"
            placeholder="Enter a topic here"
            name="s"
            className="form-control search-input"
          />
        </div>
      </div>
    </form>
  );
};

export default SearchBar;
