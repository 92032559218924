import React from "react";
import Footer from "./Footer";
import "../styles/style.scss";

const SearchLayout = ({ children }) => (
  <>
    <div id="wrapper" className="wrapper">
      {children}
      <Footer />
    </div>
  </>
);

export default SearchLayout;
