import React, { useState } from "react";
import { graphql } from "gatsby";

import SearchLayout from "../components/SearchLayout";
import Seo from "../components/Seo";
import SearchDisplay from "../components/search/SearchDisplay";
import SearchBar from "../components/search/SearchBar";
import SearchHeader from "../components/SearchHeader";

import { useFlexSearch } from "react-use-flexsearch";

// markup
const Search = ({ data }) => {
  const pageContent = data.pageContent.nodes[0];
  const [searchQuery, setSearchQuery] = useState("");
  const index = data.localSearchPages.index;
  const store = data.localSearchPages.store;

  const results = useFlexSearch(searchQuery, index, store);

  return (
    <SearchLayout>
      <SearchHeader page={pageContent}>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </SearchHeader>
      <Seo
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
      />
      <div className="container d-flex justify-content-center py-5">
        <SearchDisplay results={results} searchQuery={searchQuery} />
      </div>
    </SearchLayout>
  );
};

export const query = graphql`
  query searchPageQuery {
    localSearchPages {
      index
      store
    }
    pageContent: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/search/" }
        frontmatter: { type: { eq: "page_content" } }
      }
    ) {
      nodes {
        frontmatter {
          title
          description
          header_image {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        html
      }
    }
  }
`;

export default Search;
