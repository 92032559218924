import React from "react";

const SearchDisplay = ({ results, searchQuery }) => (
  <div>
    {searchQuery.length === 0 ? (
      <p className="text-center text-muted">
        No results yet - please enter a search query.
      </p>
    ) : results.length === 0 && searchQuery.length > 0 ? (
      <p className="text-center text-muted">
        No results found - try a different query.
      </p>
    ) : (
      results.map((result) => (
        <div key={result.id} className="mt-4">
          <p className="search-label text-uppercase text-muted">
            {result.type}
          </p>
          <a
            href={
              result.type === "Book" || result.type === "article"
                ? "/publications"
                : result.type === "Training"
                ? "/training"
                : result.type === "Upcoming Events"
                ? "/upcomingevents"
                : result.type === "othermedia"
                ? "/othermedia"
                : result.slug
            }
            className="fs-5"
          >
            {result.title}
          </a>
          <p>{result.excerpt}...</p>
        </div>
      ))
    )}
  </div>
);

export default SearchDisplay;
